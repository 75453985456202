
import { computed, defineComponent, reactive } from 'vue';
import { useForm } from '@ant-design-vue/use';

import {
  ECheckFormResponse,
  LawPayPaymentECheckResponse,
  PaymentForm,
} from '@/types/payment-form.type';
import { Validator } from '@/utils/ant-custom-validators';
import useLawpay from '@/composables/use-lawpay';
import { useGlobalProps } from '@/composables';
import { message } from 'ant-design-vue';

export default defineComponent({
  setup() {
    const { setLoading, states } = useGlobalProps();
    const { lawPayFieldID, lawPayHostedField, validateForm } = useLawpay(
      'e_check',
    );

    const form = reactive<PaymentForm['e_check']>({
      account_holder_type: 'business',
      name: '',
      business_name: '',
      given_name: '',
      surname: '',
      bank_account_number: null,
      routing_number: null,
      account_type: 'checking',
      email: '',
      address1: '',
      city: '',
      state: undefined,
      postal_code: null,
      country: '',
    });

    form.country = 'US';

    const rules = reactive({
      business_name: [
        {
          validator: Validator.isRequired(),
          type: 'string',
        },
      ],
      given_name: [
        {
          validator: Validator.isRequired(),
          type: 'string',
        },
      ],
      surname: [
        {
          validator: Validator.isRequired(),
          type: 'string',
        },
      ],
      email: [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      address1: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      city: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      state: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      postal_code: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 5),
          trigger: 'change',
          type: 'string',
        },
      ],
      country: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
    });

    /** Form validation result (AntDesign) */
    const { validateInfos, validate } = useForm(form, rules);

    const fieldsToValidate = computed(() => {
      const constants = [
        'account_holder_type',
        'bank_account_number',
        'account_type',
        'email',
        'address1',
        'city',
        'state',
        'postal_code',
        'country',
      ];

      return form.account_holder_type === 'business'
        ? [...constants, 'business_name']
        : [...constants, 'given_name', 'surname'];
    });

    const validateFn = () => validateForm(validate, fieldsToValidate.value);

    /** Execute LawPay request */
    const lawPayRequest = async (): Promise<LawPayPaymentECheckResponse> => {
      // Validate form fields integrity
      if (!(await validateFn())) {
        return Promise.reject(new Error('Invalid form fields'));
      }

      // Required data by LawPay
      const formData: ECheckFormResponse = {
        account_holder_type: form.account_holder_type,
        name: form.business_name ? form.business_name : '',
        account_type: form.account_type,
        email: form.email,
        address1: form.address1,
        city: form.city,
        state: form.state,
        postal_code: form.postal_code,
        country: form.country,
      };

      // Send data according to account holder type
      if (form.account_holder_type === 'business') {
        formData.business_name = form.business_name;
        formData.name = form.business_name ? form.business_name : '';
      } else {
        formData.given_name = form.given_name;
        formData.surname = form.surname;
        formData.name = form.given_name ? form.given_name : '';
      }

      try {
        setLoading(true);
        const result = await lawPayHostedField.value.getPaymentToken<
          LawPayPaymentECheckResponse
        >(formData);

        if (result && result !== null) {
          window.top.postMessage(result, process.env.VUE_APP_PRIMA_URL);

          return Promise.resolve(result);
        } else {
          message.success('Lawpay response is invalid.');
          return Promise.reject('Lawpay response is invalid.');
        }
      } catch (error) {
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    };

    const filterOption = (input: string, option: any) => {
      return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      form,
      validateInfos,
      lawPayFieldID,
      validateFn,
      lawPayRequest,
      lawPayHostedField,
      states,
      filterOption,
    };
  },
});
